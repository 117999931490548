// customer kamkombank

export const NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';

export const TENANT_DOMAIN = process.env.NEXT_PUBLIC_TENANT_DOMAIN;

export const SMS_SEND_DELAY = 30000;
export const STATUS_CHECK_INTERVAL = 20000;
export const REFRESH_DATA_INTERVAL = 60000;

export const DADATA_TOKEN = 'c81438ae51a4c5629ef2f4674660a6798e04854e';
export const LOCALE = 'ru';
export const PHONE_MASK = '8';
export const CURRENCY = 'RUR';

export const HEADER_INVERTED = true;
export const MAX_DOWNLOAD_FILE_SIZE = 10; // Megabytes
export const MIN_FIAS_LVL = 8;

export const OPTIONS = {};

export const WDYR = false;

export const SAVE_COOKIES = [];
export const SAVE_COOKIES_MAP = {};
export const SAVE_COOKIES_TTL = 60; //days

export const INTEGRATIONS = {
  yandexMetrika: {
    id: 95349144,
    goals: {
      number: { event: 'number' },
      vhod: { event: 'vhod' },
      gosuslugi: { event: 'gosuslugi' },
      zayavka: { event: 'zayavka' },
    },
  },
};

export const SENTRY_ENVIRONMENT = NODE_ENV_PRODUCTION ? 'production' : 'local';
export const SENTRY_DSN = 'https://d82941e8749c4c819acc30be6213c92a@sentry-ru.brainysoft.ru/5';
export const SENTRY_TRACE_SAMPLE_RATE = 0.1;
