export { css as CheckBox } from './CheckBox/styles';

export {
  // CheckBox,
  DadataInputAntd,
  DatePicker,
  Dragger,
  FieldArray,
  FormikForm,
  FormItem,
  Input,
  InputNumber,
  MaskedInput,
  PhoneInput,
  PhoneInputApprove,
  Select,
  Slider,
  Switch,
  Upload,
  Error,
} from '@custom/defaults/theme/Form';
