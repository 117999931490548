import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { Currency } from '../../components/Currency';
import { CreditDetailsT } from '@brainysoft/lk-components';
import { useTranslation } from '../../utils/useTranslation';
import { useCreditProducts } from '../../queries/useCreditProduct';
import { get } from 'lodash';
import dayjs from 'dayjs';

interface IActiveCreditInfo {
  credit: CreditDetailsT;
}

export const ActiveCreditInfo: React.FC<IActiveCreditInfo> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();
  const { credit } = props;

  const total =
    !isNaN(Number(credit?.data?.amount)) && !isNaN(Number(credit?.data?.interest))
      ? Number(credit?.data?.amount) + Number(credit?.data?.interest)
      : null;

  const creditProductId = credit?.data?.creditProductId;

  const creditProductQuery = useCreditProducts();
  const creditProduct = get(
    (creditProductQuery.data || []).filter((creditProduct) => creditProduct.id === creditProductId),
    0
  );

  return (
    <React.Fragment>
      <div className={classes.creditInfo}>
        <div className={classes.creditInfoHeader}>{t('active:termsOfYourLoan')}: </div>
        <div className={classes.creditInfoDetails}>
          <div className={classes.creditInfoRow}>
            <div>{t('active:loanAmount')}:</div>
            <div className={classes.creditInfoAmountWrapper}>
              <Currency amount={credit?.data?.amount} ratio={0.8} />
            </div>
          </div>
          {credit?.data?.interest !== undefined && (
            <div className={classes.creditInfoRow}>
              <div>{t('active:interest')}:</div>
              <div className={classes.creditInfoAmountWrapper}>
                <Currency amount={credit?.data?.interest} ratio={0.8} />
              </div>
            </div>
          )}
          {total && (
            <div className={classes.creditInfoRow}>
              <div>{t('active:amountDue')}:</div>
              <div className={classes.creditInfoAmountWrapper}>
                <Currency amount={total} ratio={0.8} />
              </div>
            </div>
          )}
          {!credit?.data?.repaymentPlanDate && !!creditProduct && (
            <div className={classes.creditInfoRow}>
              <div>{creditProduct.periodInTranches ? t('active:loanTermMonths') : t('active:loanTermDays')}</div>
              <div className={classes.creditInfoPeriodWrapper}>
                <span className={classes.creditInfoPeriod}>
                  <b>
                    {creditProduct.periodInTranches
                      ? Math.trunc(credit?.data?.period / creditProduct.trancheDuration)
                      : credit?.data?.period}
                  </b>
                </span>
              </div>
            </div>
          )}
          {credit?.data?.repaymentPlanDate && (
            <div className={classes.creditInfoRow}>
              <div>{t('active:loanRepaymentDate')}:</div>
              <div className={classes.creditInfoPeriodWrapper}>
                <span className={classes.creditInfoPeriod}>
                  <b>{dayjs(credit?.data?.repaymentPlanDate).format('DD.MM.YYYY')}</b>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
