import { css as original } from '@custom/defaults/theme/Form/CheckBox/styles';
import { merge } from 'lodash';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { primaryLight } from '../../../colors';

export const css = merge(original, {
  checkbox: {
    '& .ant-checkbox-inner': {
      borderColor: primaryLight,
      borderWidth: 2,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
