import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { primary, THEME_COLORS, white } from '@brainysoft/lk-custom/colors';
import { merge } from 'lodash';

import { css as original } from '@custom/defaults/theme/Components/Card/styles';
import Color from 'color';

const { black, grey } = THEME_COLORS;

const primaryBackground = Color(primary).mix(Color(white), 0.9).rgb().string();
const primaryLight = Color(primary).mix(Color(white), 0.3).rgb().string();

export const css = merge(original, {
  card: {
    border: `1px solid ${primaryLight}`,
    backgroundColor: primaryBackground,
    color: black,
    '&.placeholder': {
      borderStyle: 'dashed',
      color: primaryLight,
    },
    '&:hover': {
      backgroundColor: primaryBackground,
      boxShadow: '0 0 20px rgba(0,0,0,0.075)',
      '&.placeholder': {
        borderColor: primary,
        borderStyle: 'solid',
        color: primary,
      },
    },
    '&.selected': {
      backgroundColor: primaryBackground,
      border: `1px solid ${primary}`,
      color: primary,
    },
  },
  pendingWrapper: {
    color: primaryLight,
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
