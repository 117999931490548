import headerRu from './translations/ru/header.json';
import errorsRu from './translations/ru/errors.json';
import activeRu from './translations/ru/active.json';
import processesRu from './translations/ru/processes.json';
import authRu from './translations/ru/auth.json';
import widgetsRu from './translations/ru/widgets.json';
import generalRu from './translations/ru/general.json';
import contractRu from './translations/ru/contract.json';
import moneyMethodsRu from './translations/ru/moneyMethods.json';
import externalAuthRu from './translations/ru/externalAuth.json';
import pagesRu from './translations/ru/pages.json';

export const translations = {
  ru: {
    header: headerRu,
    errors: errorsRu,
    active: activeRu,
    processes: processesRu,
    auth: authRu,
    widgets: widgetsRu,
    general: generalRu,
    contract: contractRu,
    moneyMethods: moneyMethodsRu,
    externalAuth: externalAuthRu,
    pages: pagesRu,
  },
};
