import * as defaultComponents from '../default';
import { ActiveCreditInfo } from './ActiveCreditInfo';
import { LoanAppCreateWidget } from './LoanAppCreateWidget';
import { ExternalAuthGosuslugi } from './ExternalAuthGosuslugi';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...defaultComponents,
  ActiveCreditInfo,
  LoanAppCreateWidget,
  ExternalAuthGosuslugi,
};
