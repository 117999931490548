import { merge } from 'lodash';

import { css as original } from '@custom/defaults/theme/Components/ExternalAuth/styles';
import { greyText } from '@brainysoft/lk-custom/colors';
import Color from 'color';
import { generalBorderRadius } from '../../../variables';

export const css = merge(original, {
  externalAuthWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  gosuslugiLabel: {
    fontSize: '.875rem',
    color: Color(greyText).lighten(1.5).rgb().string(),
    marginBottom: '.75rem',
    lineHeight: 1.25,
  },
  externalAuthWrapperV2: {
    '& [class*="dimmer"]': {
      borderRadius: generalBorderRadius,
    },
  },
  gosuslugiContent: {
    flexDirection: 'row',
  },
  gosuslugiLogo: {
    display: 'flex',
    width: '25%',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'end',
    alignItems: 'center',
    '& svg': {
      height: '64px',
      width: 'auto',
    },
  },
  externalAuthInnerWrapper: {
    ...original.externalAuthWrapper,
    padding: '0.5rem 1rem',
  },
  gosuslugiInnerTitle: {
    ...original.gosuslugiTitle,
    textAlign: 'left',
    padding: '0 1rem',
    fontSize: '0.85rem',
    fontWeight: 'bold',
  },
  gosuslugiInnerLabel: {
    fontSize: '0.85rem',
    padding: '0.25rem',
  },
});

export default css;
