import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { merge } from 'lodash';

import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';
import { white } from '../../../colors';
import { generalBorder, generalBorderRadiusLarge } from '../../../variables';
import Color from 'color';
import { grey } from '@brainysoft/lk-custom/colors';

export const css = merge(original, {
  modal: {
    background: 'transparent',
    boxShadow: 'none',
  },
  authBackgroundImage: {
    right: '40%',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    '&:before': {
      content: '',
    },
  },
  authPageWrap: {
    background: white,
  },
  authFormWrapper: {
    maxWidth: 350,
    width: '100%',
    '& .ant-btn': {
      fontSize: '.875rem',
      height: '48px !important',
      padding: '1rem 1.25rem !important',
      textAlign: 'center !important',
      justifyContent: 'center',
    },
    '& [class*="approveWrapper"]': {
      marginBottom: 0,
    },
    '& [class*="dimmer"]': {
      borderRadius: generalBorderRadiusLarge,
    },
  },
  authFormSegmentWrapper: {
    border: generalBorder,
    borderRadius: generalBorderRadiusLarge,
    padding: '1.5rem',
    background: Color(white).alpha(0.5).rgb().toString(),
    backdropFilter: 'blur(20px)',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    pageTitle: {
      textAlign: 'left',
      fontSize: '1.5rem',
    },
    modal: {
      width: 'calc(100% - 1rem)',
    },
    authPageWrap: {
      background: 'transparent',
    },
    authFormWrapper: {
      maxWidth: '100%',
      width: '100%',
      '& .ant-btn': {
        fontSize: '.875rem',
        height: '48px !important',
        padding: '1rem 1.25rem !important',
        textAlign: 'center !important',
        justifyContent: 'center',
      },
    },
    authFormSegmentWrapper: {
      backgtound: white,
      boxShadow: `0 4px 4px ${Color(grey).alpha(0.2).rgb().string()}`,
    },
  },
});

export default css;
