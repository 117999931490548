import getConfig from 'next/config';
import { ExternalAuthProviders } from '@brainysoft/lk-components';

export const useExternalAuthProvider: () => string | undefined = () => {
  const { publicRuntimeConfig } = getConfig();

  const configProvider = publicRuntimeConfig.externalAuthProvider
    ? publicRuntimeConfig.externalAuthProvider.trim() !== 'true' &&
      publicRuntimeConfig.externalAuthProvider.trim() !== 'false'
      ? publicRuntimeConfig.externalAuthProvider.trim()
      : undefined
    : undefined;

  return configProvider;
};

export const useCheckExternalAuthProvider: (provider: ExternalAuthProviders) => boolean = (provider) => {
  const configProvider = useExternalAuthProvider();

  return configProvider === provider;
};
