import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import cls from 'classnames';

interface IDimmer {
  active: boolean;
  transparent?: boolean;
}

export const Dimmer: React.FC<React.PropsWithChildren<IDimmer>> = (props) => {
  const classes = useThemeStyles('LoadingSpinner');

  const { active, transparent = false, children } = props;

  const onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <React.Fragment>
      {!!active && (
        <div className={cls(classes.dimmer, transparent ? classes.dimmerTransparent : '')} onClick={onClick}>
          {children}
        </div>
      )}
    </React.Fragment>
  );
};
